// src/components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("authToken"); // Check token existence

  if (isAuthenticated === undefined || isAuthenticated === null) {
    return <Navigate to="/" replace />;
  }
    
  return children;
  
};

export default PrivateRoute;