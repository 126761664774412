import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showToast = (message, type) => {
  const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {},
  };

  // Customize toast color based on type
  switch (type) {
    case "success":
      options.style = { backgroundColor: "green", color: "white" };
      break;
    case "error":
      options.style = { backgroundColor: "red", color: "white" };
      break;
    case "warning":
      options.style = { backgroundColor: "orange", color: "white" };
      break;
    case "info":
      options.style = { backgroundColor: "blue", color: "white" };
      break;
    default:
      break;
  }

  toast(message, options);
};

const Toast = () => {
  return <ToastContainer />;
};

export default Toast;
