import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import LeaveDetailsModal from "./LeaveDetailsModal";
import { AlertTriangle, Trash2, CheckCircle, XCircle, MessageSquareText } from "lucide-react";
import { format } from "date-fns";

const LeaveTable = ({
  allLeaves = [], // Already filtered in LeaveFormPage
  setAllLeaves, // Parent function to update all leaves
  activeTab,
  setShowConfirmDialog,
  setSelectedLeave,
  selectedLeave,
}) => {
  // Filter leaves based on activeTab
  const filteredLeaves = useMemo(() => {
    return allLeaves.filter((leave) => {
      switch (activeTab) {
        case "pending":
          return leave.status === "Pending";
        case "approved":
          return leave.status === "Approved";
        case "rejected":
          return leave.status === "Rejected";
        default:
          return true;
      }
    });
  }, [allLeaves, activeTab]);
  // Helper function for status badge colors
  const getStatusBadgeColor = (status) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-100 text-yellow-800";
      case "Approved":
        return "bg-green-100 text-green-800";
      case "Rejected":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const handleLeaveUpdate = (updatedLeave) => {
    const updatedLeaves = allLeaves.map((leave) =>
      leave.id === updatedLeave.id ? updatedLeave : leave
    );
    setAllLeaves(updatedLeaves);
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Employee Name",
        field: "employee_name",
        sortable: true,
        filter: true,
        cellRenderer: ({ value, data }) => (
          <div className="flex items-center space-x-2">
            <span>{value || "N/A"}</span>
            {data.comments?.length > 0 && (
              <MessageSquareText
                className="text-yellow-500 h-4 w-4"
                title="Has comments"
              />
            )}
          </div>
        ),
      },
      {
        headerName: "Employee ID",
        field: "employeeId",
        sortable: true,
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Leave Type",
        field: "leaveTypes",
        filter: "agSetColumnFilter", // Ensures a dropdown for filtering
        cellRenderer: ({ value }) => (
          <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800">
            {Array.isArray(value) ? value.join(", ") : value}
          </span>
        ),
      },
      {
        headerName: "Duration",
        field: "startDate", // Field associated with the range
        sortable: true,
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterDate, cellValue) => {
            if (!cellValue) return -1; // Skip if no value in cell

            // Split cell value into startDate and endDate
            const [startDateStr, endDateStr] = cellValue.split(" to ");
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);
            const compareDate = new Date(filterDate);

            if (compareDate < startDate) {
              return -1; // Before the range
            } else if (compareDate > endDate) {
              return 1; // After the range
            }
            return 0; // Within the range
          },
          debounceMs: 200, // Add debounce for smoother filtering
        },
        cellRenderer: ({ data }) => (
          <div className="flex items-center space-x-2">
            <span>
              {data.startDate} to {data.endDate}
            </span>
            <span className="inline-flex rounded-full px-2 text-xs font-semibold bg-gray-100 text-gray-800">
              {data.daysOfLeave} days
            </span>
          </div>
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        filter: 'agSetColumnFilter',
        cellRenderer: ({ value, data }) => (
          <div className="flex items-center space-x-2">
            <span
              className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getStatusBadgeColor(
                value
              )}`}
            >
              {value}
            </span>
            {data.override && (
              <AlertTriangle
                className="text-yellow-500 h-4 w-4"
                title="This status has an override"
              />
            )}
          </div>
        ),
      },
      {
        headerName: "Submitted By",
        field: "submittedBy",
        filter: "agSetColumnFilter",
      },
      {
        headerName: "Submitted Date",
        field: "submittedAt",
        filter: "agSetColumnFilter",
        cellRenderer: ({ value }) =>
          value ? format(new Date(value), "dd-MMM-yyyy HH:mm") : "N/A",
      },
      { headerName: "Actioned By", field: "actionBy", filter: true },
      {
        headerName: "Actioned Date",
        field: "actionAt",
        filter: "agDateColumnFilter",
        cellRenderer: ({ value }) =>
          value ? format(new Date(value), "dd-MMM-yyyy HH:mm") : "N/A",
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 200, // Ensure enough space for buttons
        cellRenderer: ({ data }) => (
          <div className="flex flex-wrap items-center gap-2">
            {activeTab === "pending" && (
              <>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmDialog({
                      show: true,
                      type: "approve",
                      id: data.id,
                    });
                  }}
                  className="inline-flex items-center px-2 py-1 border border-transparent rounded-md text-xs text-white bg-green-600 hover:bg-green-700"
                >
                  <CheckCircle className="h-4 w-4 mr-0.5" />
                  Approve
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowConfirmDialog({
                      show: true,
                      type: "reject",
                      id: data.id,
                    });
                  }}
                  className="inline-flex items-center px-2 py-1 border border-transparent rounded-md text-xs text-white bg-red-600 hover:bg-red-700"
                >
                  <XCircle className="h-4 w-4 mr-0.5" />
                  Reject
                </button>
              </>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowConfirmDialog({
                  show: true,
                  type: "delete",
                  id: data.id,
                });
              }}
              className="inline-flex items-center px-1.5 py-1 border border-gray-300 rounded-md text-xs text-gray-700 bg-white hover:bg-gray-50"
            >
              <Trash2 className="h-4 w-4" />
            </button>
          </div>
        ),
      },
    ],
    [activeTab, setShowConfirmDialog]
  );

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
    setTimeout(() => {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        allColumnIds.push(column.colId);
      });
      params.columnApi.autoSizeColumns(allColumnIds, false);
    }, 100);
  };

  console.log('filteredLeaves',filteredLeaves);
  console.log('columnDefs',columnDefs);

  return (
    <div
      className="ag-theme-material"
      style={{ height: "calc(100vh - 200px)", width: "100%" }}
    >
      {filteredLeaves.length > 0 ? ( // Correctly use a ternary operator
        <AgGridReact
          rowData={filteredLeaves}
          columnDefs={columnDefs}
          onGridReady={(params) => {
            console.log('params', params);
            params?.api.sizeColumnsToFit();
          }}
          onRowClicked={(event) => {
            if (!event.event.target.closest("button")) {
              setSelectedLeave(event.data); // Opens the modal only when clicking outside buttons
            }
          }}
          rowHeight={50} // Compact rows
          headerHeight={30} // Compact headers
          domLayout="autoHeight"
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
            cellClass: "flex items-center justify-center text-xs p-1", // Center content vertically
            headerClass: "text-xs font-bold p-1",
          }}
          pagination={true}
          paginationPageSize={20}
        />
      ) : (
        <div className="flex justify-center items-center h-full text-gray-500">
          No leave requests to display.
        </div>
      )}
      {selectedLeave && (
        <LeaveDetailsModal
          leave={selectedLeave}
          isOpen={!!selectedLeave}
          onClose={() => setSelectedLeave(null)}
          onUpdateParent={handleLeaveUpdate}
        />
      )}
    </div>
  );
  
};

export default LeaveTable;
