// src/config.js

const BASE_API_URL = 'https://rosterbackend-production.up.railway.app';
// const BASE_API_URL = 'https://nodehost.mydevfactory.com:8023';
//const BASE_API_URL = 'http://localhost:5000';
const ROSTER_API_URL = `${BASE_API_URL}/api/workers/list`;

// Export both as named exports and set a default export for BASE_API_URL
export { BASE_API_URL, ROSTER_API_URL };
export default BASE_API_URL;
