import React, { useMemo, useRef, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../components/ui/card";
import { ROSTER_API_URL } from "../config";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { motion } from "framer-motion";
import BASE_API_URL from "../config";
import axios from "axios";

const RosterPage = () => {
  const gridRef = useRef();
  const daysToShow = isLeapYear ? 366 : 365;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const firstDateOfYear = new Date(currentYear, 0, 1);
  const startDate = currentDate;
  console.log("startDate", startDate);
  const authToken = localStorage.getItem("authToken");
  const [rosterData, setRosterData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startFilterDate, setStartFilterDate] = useState("");
  const [endFilterDate, setEndFilterDate] = useState("");
  const [isFilterApplied, setIsFilterApplyed] = useState(false);
  const [showAddRosterPopup, setShowAddRosterPopup] = useState(false);
  const [day, setDay] = useState("");
  const [offset, setOffset] = useState("");
  const [rosterType, setRostertype] = useState("");
  const [dayStatus, setDayStatus] = useState([
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
    "working",
  ]);
  // console.log('authToken',authToken);
  const convertExcelDate = (excelDate) => {
    const baseDate = new Date(1900, 0, 1);
    baseDate.setDate(baseDate.getDate() + excelDate - 2);
    return baseDate;
  };

  function isLeapYear() {
    const year = new Date().getFullYear();
    // Check if the year is divisible by 4 and (not divisible by 100 or divisible by 400)
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }

  const formatName = (name) => {
    if (!name) return "N/A"; // Handle missing or undefined names
    const parts = name.split(", ");
    if (parts.length === 2) {
      return `${parts[1]} ${parts[0]}`; // "FirstName LastName"
    }
    return name; // Return the original name if not in expected format
  };

  const fetchRosterData = async () => {
    try {
      // const response = await fetch(`${ROSTER_API_URL}`, {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // });
      const response = await axios.get(`${BASE_API_URL}/api/rosters/list-with-leave`, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
        },
      });

      // console.log('fetchRosterData response ', response.data);
      if (response.data) {
        const processedData = response.data.map((employee) => {
          const row = {
            ...employee,
            name: formatName(employee.name),
            start_date: convertExcelDate(
              employee.start_date
            ).toLocaleDateString(),
          };
          // console.log("employee employee employee", employee);
          for (let i = 0; i < daysToShow; i++) {
            const currentDay = new Date(startDate);
            currentDay.setDate(startDate.getDate() + i); // Increment date by i days
      
            // Format the date as YYYY-MM-DD
            const formattedDate = currentDay.toISOString().split("T")[0];
            const matchingData = employee.roster_data.find(
              (entry) => entry.date === formattedDate
            );
            if (employee.roster_data.length && employee.roster_data[i] && matchingData) {
              row[`day${i}`] = employee.roster_data[i].status;
            } else {
              row[`day${i}`] = "-";
            }
            // row[`day${i}`] = getRosterDay(
            //   employee.roster_type,
            //   i,
            //   employee.start_date
            // );
          }
          return row;
        });
        // console.log('processedData processedData',processedData);
        setRosterData(processedData);
      }
    } catch (error) {
      console.error("Error fetching roster data:", error);
    } finally {
      setLoading(false);
    }
  };

  const addRosterData = async (e) => {
    e.preventDefault();

    const data = {
      roster_type: rosterType,
      startOffset: offset,
      startDay: day,
      days: dayStatus,
    };

    try {
      const response = await axios.post(
        `${BASE_API_URL}/api/rosters/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json", // Specify the content type
            Authorization: `Bearer ${authToken}`, // Replace `yourToken` with the actual token
          },
        }
      );

      const { token, user } = response.data;

      // Store the token and user info
      localStorage.setItem("authToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      setShowAddRosterPopup(false);
      // Redirect to the dashboard
    } catch (error) {
      console.error("Login failed:", error);
      setShowAddRosterPopup(false);
    }
  };

  const getRosterDay = (rosterType, dayIndex, startDateExcel) => {
    const startDate = convertExcelDate(startDateExcel);
    const adjustedDayIndex = dayIndex % 14; // Modulo 14 for 10-4 patterns

    switch (rosterType) {
      case "1-7":
        return (dayIndex % 7) + 1;

      case "DIDO 10-4 A":
        if (adjustedDayIndex === 0) return "Drive In"; // First day is "Drive In"
        if (adjustedDayIndex === 9) return "Drive Out"; // 10th day is "Drive Out"
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R";

      case "FIFO 10-4 A":
        if (adjustedDayIndex === 0) return "Fly In"; // First day is "Fly In"
        if (adjustedDayIndex === 9) return "Fly Out"; // 10th day is "Fly Out"
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R";

      case "LOCAL 10-4 A":
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R"; // Just 1-10, then R&R

      case "DIDO 10-4 B":
        const shiftedDayIndexDIDO = (dayIndex + 7) % 14;
        if (shiftedDayIndexDIDO === 0) return "Drive In";
        if (shiftedDayIndexDIDO === 9) return "Drive Out";
        return shiftedDayIndexDIDO < 10
          ? (shiftedDayIndexDIDO % 10) + 1
          : "R&R";

      case "FIFO 10-4 B":
        const shiftedDayIndexFIFO = (dayIndex + 7) % 14;
        if (shiftedDayIndexFIFO === 0) return "Fly In";
        if (shiftedDayIndexFIFO === 9) return "Fly Out";
        return shiftedDayIndexFIFO < 10
          ? (shiftedDayIndexFIFO % 10) + 1
          : "R&R";

      case "LOCAL 10-4 B":
        return adjustedDayIndex < 10 ? (adjustedDayIndex % 10) + 1 : "R&R"; // Just 1-10, then R&R

      case "13-1 A":
        return adjustedDayIndex === 13 ? "R&R" : (adjustedDayIndex % 13) + 1;

      case "13-1 B":
        const shiftedDayIndex13B = (dayIndex + 7) % 14;
        return shiftedDayIndex13B === 13
          ? "R&R"
          : (shiftedDayIndex13B % 13) + 1;

      case "6-1":
        return dayIndex % 7 === 6 ? "R&R" : (dayIndex % 6) + 1;

      default:
        return "-"; // Empty if the roster type is unrecognized
    }
  };

  const dateHeaders = useMemo(() => {
    console.log("startFilterDate", startFilterDate);
    const headers = [];
    const monthGroups = {};
    const effectiveStartDate = startFilterDate || startDate;
    const effectiveEndDate = endFilterDate;
  
    const start = new Date(effectiveStartDate);
    const end = effectiveEndDate ? new Date(effectiveEndDate) : null;
  
    const daysToProcess = end
      ? Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1
      : daysToShow;
  
    for (let i = 0; i < daysToProcess; i++) {
      const date = new Date(start);
      date.setDate(start.getDate() + i);
  
      const month = date.toLocaleDateString("en-AU", {
        month: "long",
        year: "numeric",
      });
  
      if (!monthGroups[month]) {
        monthGroups[month] = [];
      }
  
      const dayOfWeek = date.toLocaleDateString("en-AU", { weekday: "short" });
      const dayOfMonth = date.getDate();
  
      monthGroups[month].push({
        headerName: `${dayOfMonth}\n${dayOfWeek}`,
        field: `day${i}`,
        headerTooltip: date.toLocaleDateString("en-AU", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        }),
        width: 120,
        cellRenderer: (params) => {
          const currentDay = new Date(start);
          currentDay.setDate(start.getDate() + i);
  
          const formattedDate = currentDay.toISOString().split("T")[0];
  
          const matchingData = params.data.roster_data.find(
            (entry) => entry.date === formattedDate
          );
  
          const dayStatus = matchingData ? matchingData.status : "-";
  
          const isWeekend = [0, 6].includes(currentDay.getDay());
  
          const cellStyle = {
            padding: "4px 8px",
            backgroundColor:
              dayStatus === "R&R"
                ? "#f0f0f0"
                : isWeekend
                ? "#ffebcc"
                : "#e6f7ff",
            color:
              dayStatus === "R&R"
                ? "#888888"
                : isWeekend
                ? "#cc7a00"
                : "#005f99",
          };
  
          return <span style={cellStyle}>{dayStatus}</span>;
        },
      });
    }
  
    for (const [month, columns] of Object.entries(monthGroups)) {
      headers.push({
        headerName: month,
        children: columns,
      });
    }
  
    return headers;
  }, [daysToShow, startDate, startFilterDate, endFilterDate]);
  

  const columnDefs = [
    {
      headerName: "Employee ID",
      field: "employee_number",
      pinned: "left",
      width: 140,
    },
    { headerName: "Employee Name", field: "name", pinned: "left", width: 180 },
    { headerName: "Position", field: "position", pinned: "left", width: 160 },
    { headerName: "Roster", field: "roster_type", pinned: "left", width: 130 },
    { headerName: "Section", field: "section", pinned: "left", width: 150 },
    {
      headerName: "Supervisor",
      field: "supervisor",
      pinned: "left",
      width: 200,
    },
    ...dateHeaders,
  ];

  const onGridReady = (params) => {
    params.api.sizeColumnsToFit();
  };

  useEffect(() => {
    fetchRosterData();
  }, []);

  // Add custom CSS to ensure scrollbars are always visible
  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      .always-show-scrollbars::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
      }
      .always-show-scrollbars::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .3);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
      .always-show-scrollbars {
        overflow: scroll !important;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const handleChangeStatus = (value, index) => {
    setDayStatus((prevStatus) => {
      const updatedStatus = [...prevStatus]; // Create a copy of the current array
      updatedStatus[index] = value; // Update the value at the specified index
      return updatedStatus; // Return the updated array
    });
  };

  // console.log('rosterData rosterData',rosterData);

  // console.log("columnDefs", columnDefs);

  return (
    <div className="h-screen p-4 bg-gray-100 overflow-auto">
      <Button
        onClick={() => setShowAddRosterPopup(true)}
        className="mb-4 bg-green-600 hover:bg-green-700 text-white"
      >
        Add New Roster Type
      </Button>

      {showAddRosterPopup && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="bg-white p-8 rounded-lg shadow-lg max-w-50"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
          >
            <h2 className="text-2xl font-semibold mb-4">Add New Roster Type</h2>
            <h4>Roster type</h4>
            <input
              type="text"
              id="type"
              placeholder="type here"
              value={rosterType}
              onChange={(e) => setRostertype(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
            <h4>Start Offset</h4>
            <input
              type="text"
              id="offset"
              value={offset}
              placeholder="type here"
              onChange={(e) => setOffset(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
            <h4>Start Day</h4>
            <select
              id="role"
              value={day}
              defaultValue={"Select day"}
              onChange={(e) => setDay(e.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Day</option>
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </select>
            <h4>Day Status</h4>
            <div className="grid grid-cols-7 gap-2 mb-4">
              {Array.from({ length: 14 }).map((_, i) => (
                <select
                  key={i} // Add a unique key for each item
                  id={`role-${i}`} // Make the id unique
                  value={dayStatus[i]} // Bind the value to the respective index in the state
                  onChange={(e) => handleChangeStatus(e.target.value, i)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                >
                  <option value="working">Working</option>
                  <option value="off">Off</option>
                </select>
              ))}
            </div>

            <Button
              onClick={addRosterData}
              className="bg-green-600 hover:bg-green-700 text-white mr-3"
            >
              Submit
            </Button>
            <Button
              onClick={() => setShowAddRosterPopup(false)}
              className="mt-4 bg-red-600 hover:bg-red-700 text-white"
            >
              Close
            </Button>
          </motion.div>
        </motion.div>
      )}

      <Card className="h-full w-full rounded-lg shadow-lg overflow-hidden mt-4">
        <CardHeader className="bg-gradient-to-r from-[#2c2c54] to-[#1c1c3a] text-white">
          <CardTitle className="text-2xl">Blue-Collar Roster</CardTitle>
        </CardHeader>
        <CardContent className="p-4">
          <div className="flex space-x-4 mb-4">
            <Input
              type="date"
              placeholder="Start Date"
              value={startFilterDate}
              onChange={(e) => setStartFilterDate(e.target.value)}
              className="w-1/3"
            />
            <Input
              type="date"
              placeholder="End Date"
              value={endFilterDate}
              onChange={(e) => setEndFilterDate(e.target.value)}
              className="w-1/3"
            />
            <Button
              onClick={()=>{setStartFilterDate('2025-01-01'); setEndFilterDate('2025-12-31')}}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              Reset Filter
            </Button>
          </div>
          <div className="ag-theme-material w-full h-[calc(100vh-280px)] always-show-scrollbars">
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rosterData}
              defaultColDef={{
                resizable: true,
                sortable: true,
                filter: true,
              }}
              onGridReady={onGridReady}
              className="w-full h-full"
              suppressDragLeaveHidesColumns={true}
              alwaysShowHorizontalScroll={true}
              alwaysShowVerticalScroll={true}
              headerHeight={48}
              suppressMovableColumns={false}
              suppressColumnMoveAnimation={true}
              enableCellTextSelection={true}
              ensureDomOrder={true}
              suppressColumnVirtualisation={true}
              rowBuffer={0}
              suppressScrollOnNewData={true}
              maintainColumnOrder={true}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RosterPage;
