// context/RosterContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import BASE_API_URL from '../config';
import { useNavigate } from 'react-router-dom';

export const RosterContext = createContext();

export const RosterProvider = ({ children }) => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const firstDateOfYear = new Date(currentYear, 0, 1);
  const startDate = firstDateOfYear;
  const [rosterData, setRosterData] = useState([]);
  const authToken = localStorage.getItem("authToken");
  const daysToShow = 90;

  // Fetch roster data from the API
  const fetchRosterData = async () => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/workers/list`, {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the token in the Authorization header
        },
      });
      // console.log('response', response);
      const processedData = processRosterData(response.data.workers);
      setRosterData(processedData);
    } catch (error) {
     if(error.response.data.message === 'Forbidden: Invalid token'){
      localStorage.clear();
      navigate('/', { replace: true })
     }
      console.error('Error fetching roster data:', error);
    }
  };

  // Process the fetched roster data
  const processRosterData = (data) => {
    return data.map((employee) => {
      const row = {
        ...employee,
        name: formatName(employee.name),
        start_date: convertExcelDate(
          employee.start_date
        ).toLocaleDateString(),
      };
      for (let i = 0; i < daysToShow; i++) {
        const currentDay = new Date(startDate);
        currentDay.setDate(startDate.getDate() + i); // Increment date by i days
  
        // Format the date as YYYY-MM-DD
        const formattedDate = currentDay.toISOString().split("T")[0];
        const matchingData = employee.roster_data.find(
          (entry) => entry.date === formattedDate
        );
        if (employee.roster_data.length && employee.roster_data[i] && matchingData) {
          row[`day${i}`] = employee.roster_data[i].status;
        } else {
          row[`day${i}`] = "-";
        }
      }
      return row;
    });
  };

  const formatName = (name) => {
    const [lastName, firstName] = name.split(", ");
    return `${firstName || ''} ${lastName || ''}`;
  };

  const convertExcelDate = (excelDate) => {
    const baseDate = new Date(1900, 0, 1);
    baseDate.setDate(baseDate.getDate() + excelDate - 2);
    return baseDate;
  };

  const getRosterDay = (rosterType, dayIndex, startDateExcel) => {
    const startDate = convertExcelDate(startDateExcel);
    const dayOfWeek = new Date(startDate.getTime() + dayIndex * 86400000).getDay();
    switch (rosterType) {
      case "1-7":
        return (dayIndex % 7) + 1;
      case "DIDO 10-4 A":
      case "FIFO 10-4 A":
      case "LOCAL 10-4 A":
        return dayIndex % 14 < 10 ? ((dayIndex % 10) + 1) : "R&R";
      case "DIDO 10-4 B":
      case "FIFO 10-4 B":
      case "LOCAL 10-4 B":
        return (dayIndex + 7) % 14 < 10 ? (((dayIndex + 7) % 10) + 1) : "R&R";
      case "13-1 A":
        return dayOfWeek === 0 ? "R&R" : (dayIndex % 13) + 1;
      case "13-1 B":
        return (dayOfWeek === 0 && dayIndex % 14 === 13) ? "R&R" : (dayIndex % 13) + 1;
      case "6-1":
        return dayOfWeek === 0 ? "R&R" : dayOfWeek;
      default:
        return "";
    }
  };

  useEffect(() => {
    fetchRosterData();
  }, []);

  return (
    <RosterContext.Provider value={{ rosterData, fetchRosterData }}>
      {children}
    </RosterContext.Provider>
  );
};
